





















































































































































import { defineComponent } from "@vue/composition-api";
import APP_CONFIG from "@/apps/core/modules/config";
import { isKonsulen } from "@/apps/accounts/modules/store";
import { toUserDateFormat } from "@/apps/core/modules/utils/datetime";

export default defineComponent({
  name: "AktivitasKlinisListBox",
  components: {
    Avatar: () => import("@/apps/core/components/Avatar.vue"),
    AktivitasKomponenTag: () =>
      import("@/apps/aktivitas/components/AktivitasKomponenTag.vue"),
    // ContextMenu: () => import("@/apps/core/components/ContextMenu.vue"),
  },
  props: {
    aktivitas: { type: Object, required: true },
    verifikasi: String,
  },
  setup(props, { emit }) {
    const aktivitas = props.aktivitas;
    let sortedDiagnosisList = [];
    const diagnosisList = aktivitas.perawatan.diagnosisList;
    const diagLength = diagnosisList.length;
    const keterampilanLength = aktivitas.aktKeterampilanList.length;
    const pemeriksaanLength = aktivitas.aktPemeriksaanList.length;
    const tatalaksanaLength = aktivitas.aktTatalaksanaList.length;
    // Ordering untuk diagnosisList
    sortedDiagnosisList = diagnosisList.sort(
      (el1: Record<string, any>, el2: Record<string, any>) => {
        if (el1.utama && !el2.utama) return -1;
        if (!el1.utama && el2.utama) return 1;
        if (el1.diagnosisNama > el2.diagnosisNama) return 1;
        if (el1.diagnosisNama < el2.diagnosisNama) return -1;
        return 0;
      }
    );
    sortedDiagnosisList.forEach((aktDiag: Record<string, string>) => {
      aktDiag.nama = aktDiag.diagnosisNama;
    });

    aktivitas.aktPemeriksaanList.forEach((aktPem: Record<string, string>) => {
      aktPem.nama = aktPem.pemeriksaanpenunjangNama;
    });
    aktivitas.aktTatalaksanaList.forEach((aktPem: Record<string, string>) => {
      aktPem.nama = aktPem.tatalaksanaNama;
    });

    aktivitas.aktKeterampilanList.forEach((aktPem: Record<string, string>) => {
      aktPem.nama = aktPem.tindakanketerampilanNama;
    });

    let aktDiagSet = diagnosisList.filter(
      (obj: any) => obj.verifikasi === "Belum"
    );
    aktDiagSet = aktDiagSet.map((obj: any) => obj.id);

    let aktKetSet = aktivitas.aktKeterampilanList.filter(
      (obj: any) => obj.verifikasi === "Belum"
    );
    aktKetSet = aktKetSet.map((obj: any) => obj.id);
    const updateVer = async (aktivitasId: string) => {
      aktivitas.verifikasi = aktivitas.verifikasi === "Belum" ? "Belum" : "Ya";
      const url = `${APP_CONFIG.baseAPIURL}/aktivitas/${aktivitasId}/`;
      await aktivitas.updateVerifikasi(url, aktDiagSet, aktKetSet);
      emit("pop", aktivitas);
    };
    return {
      // Data
      diagLength,
      isKonsulen,
      keterampilanLength,
      pemeriksaanLength,
      sortedDiagnosisList,
      tatalaksanaLength,

      // Method
      toUserDateFormat,
      updateVer,
    };
  },
});
